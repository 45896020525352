import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useLocation } from "@reach/router"

const AboutComponent = () => {
  const pathname = useLocation().pathname
  return (
    <>
      <Container
        className="padding-top padding-bottom"
        data-pathname={pathname}
      >
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col md={10}>
                <div className="title">
                  <h1>CTemer - Emergency Training Center</h1>
                  <div className="separator"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {pathname === "/" && (
                  <>
                    <p>
                      Reconhecido pela American Safety & Health Institute
                      (ASHI), o CTEMER oferece cursos na área de urgência e
                      emergência em centros de treinamento próprio .
                    </p>
                    <p>
                      Com foco na qualificação profissional, o CTEMER utiliza
                      métodos de ensino inovadores, material didático atualizado
                      em centros de treinamento altamente equipados para
                      proporcionar simulações realísticas, preparando o aluno
                      para situações reais.
                    </p>
                    <Link className="btn btn-primary" to="/institucional">
                      Saiba mais
                    </Link>
                  </>
                )}

                {pathname.startsWith("/institucional") && (
                  <>
                    <p className="mb-1">
                      <strong>CTEMER – Emergency Training Center</strong> é um
                      centro de treinamento da American Safety & Health
                      Institute (ASHI) com ID 2096587, localizado na cidade de
                      Pelotas – RS. Foi criado por um grupo de médicos e
                      enfermeiros com longa experiência na área da urgência e
                      emergência.
                    </p>
                    <p className="mb-1">
                      Trabalhamos na capacitação profissional nesta área
                      qualificando pessoas através de renomados cursos
                      credenciados internacionalmente e nacionalmente, prezando
                      sempre pela qualidade em simulação realista.
                    </p>
                    <p>
                      Usamos métodos de ensino inovadores, material didático
                      atualizado e materiais de ótima qualidade para
                      proporcionar uma melhor simulação dos casos propostos nos
                      treinamentos.
                    </p>
                    <h5 className="text-uppercase">
                      <strong>Missão</strong>
                    </h5>
                    <p>
                      Ser referência no ensino na área da urgência e emergência,
                      trabalhar de forma responsável, educando e qualificando
                      profissionais, usando forma de ensino diferenciada visando
                      a qualidade para que o aluno saia preparado para
                      desempenhar a mais nobre de todas as funções, SALVAR
                      VIDAS!
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="about-pictures d-none d-md-block">
            <Row>
              <Col md={12} className="pb-2">
                <StaticImage
                  src="../../images/home/instituto1.png"
                  alt="Thumb"
                  placeholder="blurred"
                />
              </Col>
              <Col md={6} className="pr-2 pt-2">
                <StaticImage
                  src="../../images/home/instituto2.png"
                  alt="Thumb"
                  placeholder="blurred"
                />
              </Col>
              <Col md={6} className="pl-2 pt-2">
                <StaticImage
                  src="../../images/home/instituto3.png"
                  alt="Thumb"
                  placeholder="blurred"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AboutComponent
