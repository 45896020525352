import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Parallax } from "react-parallax";
import bgImage from "../../images/home/parallax1.png";

const NumbersComponent = () => {
  return (
    <Parallax blur={3} bgImage={bgImage} bgImageAlt="" strength={200}>
      <Container className="numbers padding-top padding-bottom">
        <Row className="justify-content-center text-center">
          <Col md={2} xs={6} className="py-3">
            <StaticImage
              src="../../images/common/numbers1.png"
              alt="Ícone"
              placeholder="tracedSVG"
              height={60}
            />
            <h1>+11000</h1>
            <p>Alunos</p>
          </Col>
          <Col md={2} xs={6} className="py-3">
            <StaticImage
              src="../../images/common/numbers2.png"
              alt="Ícone"
              placeholder="tracedSVG"
              height={60}
            />
            <h1>+20</h1>
            <p>Cursos</p>
          </Col>
          <Col md={2} xs={6} className="py-3">
            <StaticImage
              src="../../images/common/numbers3.png"
              alt="Ícone"
              placeholder="tracedSVG"
              height={60}
            />
            <h1>+8</h1>
            <p>Anos de mercado</p>
          </Col>
          <Col md={2} xs={6} className="py-3">
            <StaticImage
              src="../../images/common/numbers4.png"
              alt="Ícone"
              placeholder="tracedSVG"
              height={60}
            />
            <h1>+10</h1>
            <p>Instrutores especializados</p>
          </Col>
        </Row>
      </Container>
    </Parallax>
  );
};

export default NumbersComponent;
