import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

const CertificationsComponent = () => {
  return (
    <Container className="padding-top padding-bottom">
      <Row>
        <Col>
          <div className="title">
            <h1>
              Nossas <span>Certificações</span> e <span>Parceiros</span>
            </h1>
            <div className="separator"></div>
          </div>
        </Col>
      </Row>
      <Row className="half-padding-top half-padding-bottom align-items-center text-center">
        <Col xs={6} md={3} className="p-3 p-md-0">
          <a href="https://hsi.com/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/home/certif1.png"
              alt="Thumb"
              placeholder="blurred"
              height={100}
            />
          </a>
        </Col>

        <Col xs={6} md={3} className="p-3 p-md-0">
          <a href="https://stopthebleed.org" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/home/certif4.png"
              alt="Thumb"
              placeholder="blurred"
              height={100}
            />
          </a>
        </Col>

        <Col xs={6} md={3} className="p-3 p-md-0">
          <a href="https://curem.com.br/home" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/home/curem.png"
              alt="Thumb"
              placeholder="blurred"
              height={100}
            />
          </a>
        </Col>

        <Col xs={6} md={3} className="p-3 p-md-0">
          <a
            href="https://www.retensino.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../images/home/ret.png"
              alt="Thumb"
              placeholder="blurred"
              height={100}
            />
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default CertificationsComponent
